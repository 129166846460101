import { gql } from '@apollo/client'

export const companyRatingFields = `
id
createdAt
customerName
overallRating
review
ownerResponse
`
export const getCompanyBranchRatings = gql`
query GetCompanyBranchRatings($companyBranchId: String!) {
  getCompanyBranchRatings(companyBranchId: $companyBranchId) {
    count
    hasMore
    data {
      id
      createdAt
      customerName
      overallRating
      review
      ownerResponse
    }
  }
}
`
