/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import ThumbUpIcon from '@mui/icons-material/ThumbUpAltOutlined'
import ThumbDownIcon from '@mui/icons-material/ThumbDownAltOutlined'

import { Permission, usePermission } from '../../../modules/users'
import {
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../../../components/Modal'
import { useNavigate, useParams } from '../../../modules/navigation'
import { useCompanyBranchRating } from '../../../modules/adminCompanyRatings'
import Route from '../../../app/Route.enum'
import { capitalizeFirstLetter } from '../../../utils/string'
import { useDateFormatter } from '../../../utils/date'
import Spinner from '../../../components/Spinner'
import ServerError from '../../../components/errors/ServerError'
import CompanyRatingModal from './CompanyRatingModal'
import StarsRating from './StarsRating'
import ReviewApprobationLabel from './ReviewApprobationLabel'
import { useUpdateCompanyBranchRatingApprobationAction } from '../../../modules/adminCompanyRatings/core/adminCompanyRatings.actions.hooks'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'

const EMPTY_PLACEHOLDER = '—'

const CompanyRatingPage: React.FC = () => {
  const { t } = useTranslation()
  const { companyBranchRatingId } = useParams()
  const { data: companyBranchRating, loading, error } = useCompanyBranchRating(companyBranchRatingId!)
  const { navigate } = useNavigate()
  const canManageCompaniesReviews = usePermission(Permission.canManageCompaniesReviews)
  const formatDate = useDateFormatter()
  const [
    updateCompanyBranchRating, {
      loading: updatingApprobation,
    }] = useUpdateCompanyBranchRatingApprobationAction()
  const formatError = useErrorFormatter()

  const onClose = () => {
    navigate(Route.CompaniesRatings)
  }

  if (loading) {
    return (
      <CompanyRatingModal>
        <div className="align-center flex justify-center p-12">
          <Spinner />
        </div>
      </CompanyRatingModal>
    )
  }

  if (!!error || !companyBranchRating) {
    return (
      <CompanyRatingModal>
        <div className="px-20 py-8">
          <ServerError error={error} />
        </div>
      </CompanyRatingModal>
    )
  }

  const handleAcceptReview = async () => {
    try {
      await updateCompanyBranchRating(companyBranchRating.id, true)
      successNotification('This review is now approved and will be published')
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleRejectReview = async () => {
    try {
      await updateCompanyBranchRating(companyBranchRating.id, false)
      successNotification('This review is now rejected and will not be published')
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const customerName = capitalizeFirstLetter(companyBranchRating.customerName)
  const companyName = capitalizeFirstLetter(companyBranchRating.companyName)

  return (
    <CompanyRatingModal>
      <ModalHeader>
        <ModalTitle>
          { t('pages.admin.companyBranchRating.title', { customerName }) }
        </ModalTitle>
      </ModalHeader>
      <ModalDescription>

        <div className="mt-4 flex flex-col text-center md:flex-row md:justify-between md:text-left">

          <div className="flex flex-col gap-y-3">
            <div>
              <div className="text-base text-neutral-400">
                { t('pages.admin.companyBranchRating.overallRating') }
              </div>

              <StarsRating rating={companyBranchRating.overallRating} />
            </div>

            <div>
              <div className="text-base text-neutral-400">
                { t('pages.admin.companyBranchRating.customerName') }
              </div>

              <div className="text-lg font-bold text-neutral-600">
                { customerName }
              </div>
            </div>

            <div>
              <div className="text-base text-neutral-400">
                { t('pages.admin.companyBranchRating.companyName') }
              </div>

              <div className="text-lg font-bold text-neutral-600">
                { companyName }
              </div>
            </div>

            <div>
              <div className="text-base text-neutral-400">
                { t('pages.admin.companyBranchRating.date') }
              </div>

              <div className="text-lg font-bold text-neutral-600">
                { formatDate(companyBranchRating.createdAt) }
              </div>
            </div>
          </div>

          <div>
            <div className="mt-4 flex flex-col gap-y-3 rounded-md bg-zinc-50 p-6 md:mt-0 md:text-right">
              <div>
                <div className="text-base text-neutral-400">
                  { t('pages.admin.companyBranchRating.professionalism') }
                </div>

                { companyBranchRating.professionalismRating
                  ? (
                    <StarsRating rating={companyBranchRating.professionalismRating} small />
                    )
                  : EMPTY_PLACEHOLDER }

              </div>

              <div>
                <div className="text-base text-neutral-400">
                  { t('pages.admin.companyBranchRating.punctuality') }
                </div>

                { companyBranchRating.punctualityRating
                  ? (
                    <StarsRating rating={companyBranchRating.punctualityRating} small />
                    )
                  : EMPTY_PLACEHOLDER }
              </div>

              <div>
                <div className="text-base text-neutral-400">
                  { t('pages.admin.companyBranchRating.communication') }
                </div>

                { companyBranchRating.communicationRating
                  ? (
                    <StarsRating rating={companyBranchRating.communicationRating} small />
                    )
                  : EMPTY_PLACEHOLDER }
              </div>
            </div>
          </div>
        </div>

        { companyBranchRating.review && (
          <div className="mt-12 text-center md:text-left">
            <div className="flex flex-wrap items-center justify-center gap-x-2 md:justify-normal">
              <h2 className="text-lg font-semibold leading-none tracking-tight text-black">
                { t('pages.admin.companyBranchRating.reviewTitle', { customerName }) }
              </h2>
              <ReviewApprobationLabel reviewApprobation={companyBranchRating.reviewApprobation} />
            </div>

            <div className="my-4 max-h-[300px] overflow-auto whitespace-pre-wrap rounded-md border bg-neutral-50 p-6 text-base text-neutral-600">
              { companyBranchRating.review }
            </div>

            { canManageCompaniesReviews && (
              <div className="mb-2 flex flex-wrap items-center justify-center gap-x-4 gap-y-2 md:justify-normal">
                <div>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    startIcon={<ThumbDownIcon />}
                    onClick={handleRejectReview}
                    disabled={updatingApprobation}
                  >
                    { t('actions.reject') }
                  </Button>
                </div>

                <div>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    startIcon={<ThumbUpIcon />}
                    onClick={handleAcceptReview}
                    disabled={updatingApprobation}
                  >
                    { t('actions.approve') }
                  </Button>
                </div>

                { updatingApprobation && <Spinner small /> }
              </div>
            ) }
          </div>
        ) }

        { companyBranchRating.ownerResponse && (
          <div className="mt-6 text-center md:text-left">
            <h2 className="text-lg font-semibold leading-none tracking-tight text-black">
              { t('pages.admin.companyBranchRating.ownerResponse', { companyName }) }
            </h2>

            <div className="my-4 max-h-[300px] overflow-auto whitespace-pre-wrap rounded-md border bg-neutral-50 p-6 text-base text-neutral-600">
              { companyBranchRating.ownerResponse }
            </div>
          </div>
        ) }

      </ModalDescription>
      <ModalFooter>
        <div>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            onClick={onClose}
          >
            { t('actions.close') }
          </Button>
        </div>
      </ModalFooter>
    </CompanyRatingModal>
  )
}

export default CompanyRatingPage
