import FilledStar from '@mui/icons-material/Star'
import UnfilledStar from '@mui/icons-material/StarBorder'
import { mergeClassName } from '../../../utils/mergeClassName'

type StarsRatingProps = {
  rating: number
  max?: number
  small?: boolean
}

const StarsRating: React.FC<StarsRatingProps> = ({
  rating,
  max = 5,
  small = false,
}) => {
  return (
    <div className={mergeClassName(
      'inline-flex items-center justify-center gap-x-2 text-yellow-500 child:!text-[36px]',
      small && 'child:!text-[28px]',
    )}
    >
      { Array.from({ length: max }).map((_, index) => {
        const key = index + 1
        const filled = !!(rating && rating >= key)
        const Icon = filled ? FilledStar : UnfilledStar

        return (
          <Icon
            key={key}
          />
        )
      }) }
    </div>
  )
}

export default StarsRating
