import { gql } from '@apollo/client'

import { companyRatingFields } from '../../companyRatings/core/companyRatings.queries'

export const fullCompanyRatingsField = `
${companyRatingFields}
reviewApprobation
professionalismRating
punctualityRating
communicationRating
moveId
companyBranchId
companyName
`

export const getCompaniesBranchesRatings = gql`
query Admin_getCompaniesBranchesRatings($query: PaginatedQuery, $approvedStatusFilter: [ApprovedStatusFilterValue!]) {
  admin_getCompaniesBranchesRatings(query: $query, approvedStatusFilter: $approvedStatusFilter) {
    count
    hasMore
    data {
      ${fullCompanyRatingsField}
    }
    count
    hasMore
  }
}
`

export const getCompanyBranchRating = gql`
query Admin_getCompanyBranchRating($companyBranchRatingId: String!) {
  admin_getCompanyBranchRating(companyBranchRatingId: $companyBranchRatingId) {
    ${fullCompanyRatingsField}
  }
}
`
